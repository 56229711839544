<!-- Desktop & tablet size layout -->
<div *ngIf="!_loading" class="row p-dev-detail">
  <ng-template [ngIf]="_errorMessage">
    <div class="col-12 mt-2">
      <p>Some errors occurred. Please refresh the website or login again.</p>
      <strong>Error message : </strong>
      <div class="warning-block">
        {{ _errorMessage }}
      </div>
    </div>
  </ng-template>
  <ng-template [ngIf]="!_errorMessage">
    <!-- left -->
    <div class="col-lg-6 col-12 left pt-2">
      <div class="titlebar">
        <h4 class="mb-0">
          <i class="fas fa-arrow-alt-circle-left green-2 icon-arrow" [routerLink]="['/app/device/devices']"></i>
          {{ _dev.virtualName }}
        </h4>
        <div class="status mx-3 pl-3">
          <strong>STATUS</strong>
          <div class="d-flex align-items-center">
            <div [ngSwitch]="_dev?.onlineStatus" class="d-inline-block">
              <ng-template [ngSwitchCase]="_enumOnlineStatus.Online">
                <div class="ball status-Online" data-toggle="tooltip"></div>
              </ng-template>
              <ng-template [ngSwitchCase]="_enumOnlineStatus.Disconnect">
                <div class="ball status-Disconnect" data-toggle="tooltip"></div>
              </ng-template>
              <ng-template [ngSwitchCase]="_enumOnlineStatus.Offline">
                <div class="ball status-Offline" data-toggle="tooltip"></div>
              </ng-template>
              <ng-template [ngSwitchCase]="_enumOnlineStatus.Syncing">
                <div class="ball status-Syncing" data-toggle="tooltip"></div>
              </ng-template>
              <ng-template [ngSwitchCase]="_enumOnlineStatus.Error">
                <div class="ball status-Error" data-toggle="tooltip"></div>
              </ng-template>
            </div>
            <span class="ml-2">
              {{ _enumOnlineStatus[_dev?.onlineStatus] }}
            </span>
          </div>
        </div>
        <button id="id_dev_do_refresh" type="button" class="btn btn-sm btn-action btn-light ml-auto"
          (click)="refreshPlayer()">
          <i class="fas fa-redo-alt"></i>
          <span class="ml-2 d-none d-md-inline-block">Refresh</span>
        </button>
      </div>
      <na-device-screenshot-info [device]="_dev" [licenseScope]="_licenseScopeMap"
        [rotation]="_dev?.currentSettings[constantSvc.DEVKEY_HD_VIDEO_ROTATION]"
        (onScreenshotTaken)="onScreenshotTaken($event)"></na-device-screenshot-info>
      <div class="my-2 layout-desktop">
        <div class="action-wrapper">
          <!-- reboot btn -->
          <button *ngIf="supportReboot" id="id_dev_do_reboot" type="button" class="btn btn-sm btn-action btn-light"
            [disabled]="!isRebootValid" data-toggle="modal" data-target="#devFuncModalHost"
            (click)="playDevFunc(devFuncSvc.FUNCNAME_REBOOT)">
            <i class="icon fas fa-redo-alt"></i>
            <span class="ml-2 name">{{ 'key-dev-reboot' | translate | capitalFirstWord }}</span>
            <span class="ml-2" *ngIf="_rebootCounter > 0" class="d-inline-block">
              - {{ _rebootCounter }} s
            </span>
          </button>
          <!-- fw-udpate btn -->
          <button *ngIf="supportFwUpdate" id="id_dev_do_fwUpdate" type="button" class="btn btn-sm btn-action btn-light"
            [disabled]="!isFirmwareUpdateValid" data-toggle="modal" data-target="#devFuncModalHost"
            (click)="playDevFunc(devFuncSvc.FUNCNAME_FIRMWARE)">
            <i class="icon fas fa-upload"></i>
            <span class="ml-2 name">{{ 'key-dev-firmwareUpdate' | translate | capitalFirstWord }}</span>
            <span class="ml-2" *ngIf="_firmwareCounter > 0" class="d-inline-block">
              - {{ _firmwareCounter }} s
            </span>
          </button>
          <!-- apk-update btn -->
          <button *ngIf="supportAPKUpdate" id="id_dev_do_apkUpdate" type="button"
            class="btn btn-sm btn-action btn-light" [disabled]="!isAPKUpdateValid" data-toggle="modal"
            data-target="#devFuncModalHost" (click)="playDevFunc(devFuncSvc.FUNCNAME_APK)">
            <i class="icon fas fa-external-link-square-alt"></i>
            <span class="ml-2 name">{{ 'key-dev-apkUpdate' | translate | capitalFirstWord }}</span>
          </button>
          <!-- basic config btn -->
          <button *ngIf="supportBasicConfig" id="id_dev_do_basicConfig" type="button"
            class="btn btn-sm btn-action btn-light" [disabled]="!isBasicConfigValid" data-toggle="modal"
            data-target="#devFuncModalHost" (click)="playDevFunc(devFuncSvc.FUNCNAME_BASIC_CONFIG)">
            <i class="icon fas fa-sliders-h"></i>
            <span class="ml-2 name">{{ 'key-dev-basicConfig' | translate | capitalFirstWord }}</span>
            <span class="ml-2" *ngIf="_basicConfigCounter > 0" class="d-inline-block">
              - {{ _basicConfigCounter }} s
            </span>
          </button>
          <!-- net config btn -->
          <button *ngIf="supportNetConfig" id="id_dev_do_netConfig" type="button"
            class="btn btn-sm btn-action btn-light" [disabled]="!isNetConfigValid" data-toggle="modal"
            data-target="#devFuncModalHost" (click)="playDevFunc(devFuncSvc.FUNCNAME_NET_CONFIG)">
            <i class="icon fas fa-network-wired"></i>
            <span class="ml-2 name">{{ 'key-dev-netConfig' | translate | capitalFirstWord }}</span>
            <span class="ml-2" *ngIf="_netConfigCounter > 0" class="d-inline-block">
              - {{ _netConfigCounter }} s
            </span>
          </button>
          <!-- security btn -->
          <button *ngIf="supportDeviceSecurity" id="id_dev_do_changePwd" type="button"
            class="btn btn-sm btn-action btn-light" [disabled]="!isDeviceSecurityValid" data-toggle="modal"
            data-target="#devFuncModalHost" (click)="playDevFunc(devFuncSvc.FUNCNAME_SECURITY)">
            <i class="icon fas fa-lock"></i>
            <span class="ml-2 name">{{ 'key-security' | translate | capitalFirstWord }}</span>
            <span class="ml-2" *ngIf="_securityCounter > 0" class="d-inline-block">
              - {{ _securityCounter }} s
            </span>
          </button>
          <!-- troubleshoot btn -->
          <button *ngIf="supportTroubleshoot" id="id_dev_do_troubleshoot" type="button"
            class="btn btn-sm btn-action btn-light" [disabled]="!isTroubleshootValid" data-toggle="modal"
            data-target="#devFuncModalHost" (click)="playDevFunc(devFuncSvc.FUNCNAME_ISSUE_REPORT)">
            <i class="icon fas fa-tools"></i>
            <span class="ml-2 name">{{ 'key-troubleshoot' | translate | capitalFirstWord }}</span>
          </button>
          <!-- activity btn -->
          <button *ngIf="supportViewDeviceActivity" id="id_dev_do_viewAct" type="button"
            class="btn btn-sm btn-action btn-light" [disabled]="!isViewDeviceActivityValid" data-toggle="modal"
            data-target="#actionTrackModal" (click)="showActivityDlg()">
            <i class="icon fas fa-radiation"></i>
            <span class="ml-2 name">{{ 'key-dev-activity' | translate | capitalFirstWord }}</span>
            <span *ngIf="_hasPendingTask" class="icon-rt fas fa-history"></span>
          </button>
          <!-- alert btn -->
          <button *ngIf="supportDeviceAlertUpdate" id="id_dev_do_alert" type="button"
            class="btn btn-sm btn-action btn-light" [disabled]="!isDeviceAlertUpdateValid" data-toggle="modal"
            data-target="#devFuncModalHost" (click)="playDevFunc(devFuncSvc.FUNCNAME_EMAIL_ALERT_CONFIG)">
            <i class="icon fas fa-envelope-open-text"></i>
            <span class="ml-2 name">Alert</span>
          </button>
          <!-- otp btn -->
          <button *ngIf="supportOTP" id="id_dev_do_otp" type="button" class="btn btn-sm btn-action btn-light"
            [disabled]="!isOTPValid" data-toggle="modal" data-target="#devFuncModalHost"
            (click)="playDevFunc(devFuncSvc.FUNCNAME_OTP)">
            <i class="icon fas fa-key"></i>
            <span class="ml-2 name">OTP</span>
          </button>
          <!-- add device label -->
          <button id="id_dev_do_add_label" type="button" class="btn btn-sm btn-action btn-light" data-toggle="modal"
            data-target="#devLabelFuncModalHost" (click)="addDeviceLabel()">
            <i class="icon fas fa-tag"></i>
            <span class="ml-2 name">Add label</span>
          </button>
          <button *ngIf="supportLock" id="id_dev_do_lock" type="button" class="btn btn-sm btn-action btn-light"
            [disabled]="!isLockValid" (click)="lockDevice(true)">
            <span class="ml-2 name">Lock</span>
          </button>
          <button *ngIf="supportUnlock" id="id_dev_do_unlock" type="button" class="btn btn-sm btn-action btn-light"
            [disabled]="!isUnlockValid" (click)="lockDevice(false)">
            <span class="ml-2 name">Unlock</span>
          </button>
          <!-- script (hidden) -->
          <button *ngIf="supportCAScript" id="id_dev_do_sc" type="button" class="btn btn-sm btn-action btn-light"
            data-toggle="modal" data-target="#devFuncModalHost" [disabled]="!isCAScriptValid"
            (click)="playDevFunc(devFuncSvc.FUNCNAME_SCRIPT)">
            <i class="icon fas fa-scroll"></i>
            <span class="ml-2 name">Script</span>
          </button>
          <!-- sync (hidden) -->
          <button id="id_dev_do_sync" type="button" class="btn btn-sm btn-action btn-light d-none"
            (click)="syncDevice()">
            <span class="ml-2 name">Sync</span>
          </button>
        </div>
      </div>
    </div>
    <!-- right -->
    <div class="col-lg-6 col-12 pt-1 right">
      <!-- basic -->
      <div>
        <div class="info-title">
          {{ 'key-general' | translate | capitalFirstWord }}
        </div>
        <div class='ml-4'>
          <na-device-general-info [device]="_dev"></na-device-general-info>
        </div>
      </div>
      <!-- network -->
      <div>
        <div class="info-title">{{ 'key-network' | translate | capitalFirstWord }}</div>
        <div class='ml-4'>
          <na-device-network-info [device]="_dev" [networkType]="_currentNetworkType"></na-device-network-info>
        </div>
      </div>
      <!-- policy -->
      <div>
        <div class="info-title">
          Policy
          <span *ngIf="!_licenseScopeMap[_enumLicenseScopeType.devicePolicy];" class="ml-2 red-1 note">
            ( Not applicable )
          </span>
        </div>
        <div class="ml-4">
          <na-device-policy-info [device]="_dev" [licenseScope]="_licenseScopeMap"
            [lock]="_lockMap"></na-device-policy-info>
        </div>
      </div>
      <!-- license -->
      <div *ngIf="supportViewLicense">
        <div class="info-title">
          {{ 'key-license' | translate | capitalFirstWord }}
          <button *ngIf="supportAddLicense" id="id_dev_link_license" type="button"
            class="btn btn-sm btn-action btn-light ml-4" [routerLink]="['/app/license/device']"
            [disabled]="_loadingLicenses">
            Add license
          </button>
        </div>
        <div class='ml-4'>
          <na-device-license-info [license]="_licenseInfo"></na-device-license-info>
        </div>
      </div>
    </div>
  </ng-template>
  <div *ngIf="!_errorMessage" class="row layout-mobile">
    <div id="mobileCtrlBlock" class="fixed-bottom">
      <div class="control-block-bottom">
        <div *ngIf="supportReboot" class="control-item" [class.disabled]="!isRebootValid" data-toggle="modal"
          data-target="#devFuncModalHost"
          (click)="isRebootValid ? playDevFunc(devFuncSvc.FUNCNAME_REBOOT) : $event.stopPropagation()">
          <div class="icon fas fa-redo-alt"></div>
          <span>Reboot</span>
        </div>
        <div *ngIf="supportBasicConfig" class="control-item" [class.disabled]="!isBasicConfigValid" data-toggle="modal"
          data-target="#devFuncModalHost"
          (click)="isBasicConfigValid ? playDevFunc(devFuncSvc.FUNCNAME_BASIC_CONFIG) : $event.stopPropagation()">
          <div class="icon fas fa-sliders-h"></div>
          <span>Basic</span>
        </div>
        <div *ngIf="supportTroubleshoot" class="control-item" [class.disabled]="!isTroubleshootValid"
          data-toggle="modal" data-target="#devFuncModalHost"
          (click)="isTroubleshootValid ? playDevFunc(devFuncSvc.FUNCNAME_ISSUE_REPORT) : $event.stopPropagation()">
          <div class="icon fas fa-tools"></div>
          <span>Debug</span>
        </div>
        <div *ngIf="supportViewDeviceActivity" [class.disabled]="!isViewDeviceActivityValid" data-toggle="modal"
          data-target="#actionTrackModal"
          (click)="isViewDeviceActivityValid ? showActivityDlg() : $event.stopPropagation()" class="control-item">
          <div class="icon fas fa-radiation"></div>
          <span>Activity</span>
          <div *ngIf="_hasPendingTask" class="notification">*</div>
        </div>
        <div *ngIf="supportNetConfig" class="control-item" [class.disabled]="!isNetConfigValid" data-toggle="modal"
          data-target="#devFuncModalHost"
          (click)="isNetConfigValid ? playDevFunc(devFuncSvc.FUNCNAME_NET_CONFIG) : $event.stopPropagation()">
          <div class="icon fas fa-network-wired"></div>
          <span>Network</span>
        </div>
        <div *ngIf="supportFwUpdate" class="control-item" [class.disabled]="!isFirmwareUpdateValid" data-toggle="modal"
          data-target="#devFuncModalHost"
          (click)="isFirmwareUpdateValid ? playDevFunc(devFuncSvc.FUNCNAME_FIRMWARE) : $event.stopPropagation()">
          <div class="icon fas fa-upload"></div>
          <span>Firmware</span>
        </div>
        <div *ngIf="supportAPKUpdate" class="control-item" [class.disabled]="!isAPKUpdateValid" data-toggle="modal"
          data-target="#devFuncModalHost"
          (click)="isAPKUpdateValid ? playDevFunc(devFuncSvc.FUNCNAME_APK) : $event.stopPropagation()">
          <div class="icon fas fa-angle-double-up"></div>
          <span>APK</span>
        </div>
        <div *ngIf="supportDeviceSecurity" class="control-item" class="action-button"
          [class.disabled]="!isDeviceSecurityValid" data-toggle="modal" data-target="#devFuncModalHost"
          (click)="isDeviceSecurityValid ? playDevFunc(devFuncSvc.FUNCNAME_SECURITY) : $event.stopPropagation()">
          <div class="icon fas fa-lock">
          </div>
          <span>Security</span>
        </div>
        <div *ngIf="supportDeviceAlertUpdate" class="control-item" [class.disabled]="!isDeviceAlertUpdateValid"
          data-toggle="modal" data-target="#devFuncModalHost"
          (click)="isDeviceAlertUpdateValid ? playDevFunc(devFuncSvc.FUNCNAME_EMAIL_ALERT_CONFIG) : $event.stopPropagation()">
          <div class="icon fas fa-envelope-open-text"></div>
          <span>Alert</span>
        </div>
        <div *ngIf="supportOTP" [class.disabled]="!isOTPValid" data-toggle="modal" data-target="#devFunModalHost"
          (click)="isOTPValid ? playDevFunc(devFuncSvc.FUNCNAME_OTP) : $event.stopPropagation()" class="control-item">
          <div class="icon fas fa-key"></div>
          <span>OTP</span>
        </div>
        <div *ngIf="supportLock" class="control-item" [class.disabled]="!isLockValid"
          (click)="isLockValid ? lockDevice(true) : $event.stopPropagation()">
          <span class="ml-2 name">Lock</span>
        </div>
        <div *ngIf="supportUnlock" class="control-item" [class.disabled]="!isUnlockValid"
          (click)="isUnlockValid ? lockDevice(false) : $event.stopPropagation()">
          <span class="ml-2 name">Unlock</span>
        </div>
        <div *ngIf="supportCAScript" class="control-item" [class.disabled]="!isCAScriptValid" data-toggle="modal"
          data-target="#devFuncModalHost"
          (click)="isCAScriptValid ? playDevFunc(devFuncSvc.FUNCNAME_SCRIPT) : $event.stopPropagation()">
          <span class="ml-2 name">Script</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="_loading" class="loading-block">
  <p>Please wait...</p>
  <div class="loading-box"></div>
</div>

<div id="devFuncModalHost" class="modal fade" tabindex="-1" data-backdrop="static" data-keyboard="false" role="dialog">
  <ng-template dev-func-host></ng-template>
</div>
<div id="devLabelFuncModalHost" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog">
  <ng-template dev-label-func-host></ng-template>
</div>

<!-- activity track dialog -->
<div *ngIf="_licenseScopeMap[_enumLicenseScopeType.activityHistory]" id='actionTrackModal' tabindex="-1"
  class='modal fade' data-backdrop='static' data-keyboard='false' role='dialog'>
  <div class='modal-dialog config-dlg'>
    <div class='modal-content'>
      <div class='modal-header'>
        <span class="modal-title">{{ 'key-dev-activity' | translate | capitalFirstWord }}</span>
        <button #dlgClose type="button" class="close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class='modal-body'>
        <ng-template [ngIf]="_showActivityDlg">
          <na-device-activity [device]="_dev" [showTrackTime]="true" [allowRefresh]="true" [allowClose]="true"
            [showBorder]="false" (onClosed)="onActivityDlgClosed()">
          </na-device-activity>
        </ng-template>
        <button #activityCloseBtn type="button" class="btn btn-outline-success d-none" data-dismiss="modal">
          <span class="sr-only">Close user activity dialog</span>
        </button>
      </div>
    </div>
  </div>
</div>