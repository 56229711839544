import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { map, concatMap, takeUntil, debounceTime, share, filter, buffer, bufferWhen, startWith, distinctUntilChanged, scan, pairwise, tap } from 'rxjs/operators';

import { DeviceInfo } from '../../device/data/device-info';
import { LicenseBaseDlgComponent } from './license-base-dlg.component';
import { DialogPage, SortType, TableHeaderHandler, ITableSorter, ITableFilter, IClass } from '../../../lib/common/common.data';
import { ILicenseCategoryInfo, ILicenseInfo } from '../../../API/v1/License/api.license.common';
import { Logger } from '../../../lib/common/logger';

class ILicenseRelocateInfo {
    token: string;
    category: string;
    type: string;
    code: string;
    expireDate: string;
    isActivated: boolean;
    isTransferable: boolean;
    isExpired: boolean;
    from: string; //from which virtual device or from account.
    to: string;
    selected: boolean;
    showName: string;
}

@Component({
    selector: 'license-reallocate',
    templateUrl: './license-dlg-reallocate.component.html',
    styleUrls: ['./license-dlg-reallocate.component.css', './license-dlg.style.css']
})
export class LicenseDlgReAllocateComponent extends LicenseBaseDlgComponent implements OnInit, OnDestroy, IClass {
    className: string;
    @Input()
    set devices(devs: DeviceInfo[]) {
        if (!devs) {
            return;
        }

        this._devices = devs;
    }

    private _allLicenseMap: { [token: string]: boolean } = {};
    _devLicenseRecord: { [vID: string]: { device: DeviceInfo, licenseList: ILicenseRelocateInfo[] } } = {};

    private readonly ACCOUNT_ALIAS: string = '---';
    _loading: boolean = false;
    _selectDevice: DeviceInfo;
    _confirmDevLicenseList: {
        device: DeviceInfo,
        originalLicenseTypeList: { type: string, showName: string }[],
        originalAllocation: {
            [type: string]: { changeType: string, licenseInfo: ILicenseRelocateInfo }[]
        },
        newLicenseTypeList: { type: string, showName: string }[],
        newAllocation: {
            [type: string]: { changeType: string, licenseInfo: ILicenseRelocateInfo }[]
        }
    }[] = [];
    _licenseAssignResultList: { targetDevice: DeviceInfo, licenseCodeList: string[], isFault: boolean, errorMessage?: string }[] = [];
    private _playerToSwitch: DeviceInfo;
    _showSwitchPlayerWarning: boolean = false;
    _enumSortType: typeof SortType = SortType;

    _playerTableHeaderHandler: TableHeaderHandler = new TableHeaderHandler();
    _assignableTableHeaderHandler: TableHeaderHandler = new TableHeaderHandler();

    _assignableLicenseOwnerList: { displayName: string, deviceID: string }[] = [];
    _currentAssignableLicenseOwner: { displayName: string, deviceID: string } = null;
    _displayLicenseOwnerDevices: { displayName: string, deviceID: string }[] = [];
    _displayLicenseRequestorDevices: DeviceInfo[] = [];

    private _searchLicenseRequestorSubscription: Subscription;
    private _searchLicenseRequestorRef: ElementRef;
    @ViewChild('searchLicenseRequestor')
    set searchLicenseRequestor(v: ElementRef) {
        if (v && this._searchLicenseRequestorRef !== v) {
            if (this._searchLicenseRequestorSubscription) {
                this._searchLicenseRequestorSubscription.unsubscribe();
            }

            this._searchLicenseRequestorRef = v;
            this._searchLicenseRequestorSubscription = fromEvent(this._searchLicenseRequestorRef.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this.refactorLicenseRequestorDevices(e.target.value.toLocaleLowerCase());
            });
        }
    }

    private _searchLicenseOwnerSubscription: Subscription;
    private _searchLicenseOwnerRef: ElementRef;
    @ViewChild('searchLicenseOwner')
    set searchLicenseOwner(v: ElementRef) {
        if (v && this._searchLicenseOwnerRef !== v) {
            if (this._searchLicenseOwnerSubscription) {
                this._searchLicenseOwnerSubscription.unsubscribe();
            }

            this._searchLicenseOwnerRef = v;
            this._searchLicenseOwnerSubscription = fromEvent(this._searchLicenseOwnerRef.nativeElement, 'input').pipe(
                debounceTime(200),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this.refactorLicenseOwnerDevices(e.target.value.toLocaleLowerCase());
            });
        }
    }

    ngOnInit(): void {
        this.className = 'License-reallocate-form';
        super.ngOnInit();

        if (this._devices.length > 0) {
            const comparedAccountID: string = this.accountSvc.isEnterprise() ? this.accountSvc.enterpriseAccountID : this.accountSvc.accountID;
            this._legalDevices = this._devices.filter(d => d.virtualDeviceOwnerID === comparedAccountID);
            this._legalDevices.forEach(d => {
                if (d.virtualId) {
                    this._devLicenseRecord[d.virtualId] = { device: d, licenseList: [] }
                }
            });

            this._devLicenseRecord[this.ACCOUNT_ALIAS] = { device: null, licenseList: [] };

            this.goNext();
        }

        this._playerTableHeaderHandler
            .sortChanged
            .pipe(takeUntil(this._unsubscribe$))
            .subscribe((result: { columnName: string, dataKey: string, sorter: ITableSorter }) => {
                this.filterAssignedLicenses();
            });
        this._assignableTableHeaderHandler
            .sortChanged
            .pipe(takeUntil(this._unsubscribe$))
            .subscribe((result: { columnName: string, dataKey: string, sorter: ITableSorter }) => {
                this.filterAssignableLicenses();
            });
        this._assignableTableHeaderHandler
            .filterChanged
            .pipe(takeUntil(this._unsubscribe$))
            .subscribe((result: { columnName: string, dataKey: string, filter: ITableFilter }) => {
                this.filterAssignableLicenses();
            });
    }

    ngOnDestroy(): void {
        this._searchLicenseRequestorSubscription.unsubscribe();
        this._searchLicenseOwnerSubscription.unsubscribe();
        super.ngOnDestroy();
    }

    selectPlayer(dev: DeviceInfo): void {
        if (this.isLicenseChanged()) {
            this._showSwitchPlayerWarning = true;
            this._playerToSwitch = dev;
            return;
        }

        if (this._selectDevice !== dev) {
            this._selectDevice = dev;
        }
    }

    refresh(): void {
        this.init(true);
    }

    private filterAssignedLicenses(): void {
        if (!this._selectDevice || !this._selectDevice.virtualId) {
            return;
        }

        let sortKey: string;
        let sortType: SortType = SortType.none;
        //sort
        for (const header_info of this._playerTableHeaderHandler.list) {
            if (header_info.sorter && header_info.sorter.currentSortType !== SortType.none) {
                sortKey = header_info.dataKey;
                sortType = header_info.sorter.currentSortType;
                break;
            }
        }

        if (sortKey && sortType !== SortType.none) {
            this._devLicenseRecord[this._selectDevice.virtualId].licenseList = this._devLicenseRecord[this._selectDevice.virtualId].licenseList.sort((a: ILicenseRelocateInfo, b: ILicenseRelocateInfo) => {
                if (a[sortKey] === undefined) {
                    return -1;
                }
                if (b[sortKey] === undefined) {
                    return -1;
                }

                if (a[sortKey] === b[sortKey]) {
                    return 0;
                }
                else if (a[sortKey] > b[sortKey]) {
                    return sortType === SortType.ascend ? -1 : 1;
                }
                else {
                    return sortType === SortType.ascend ? 1 : -1;
                }
            });
        }
    }

    private filterAssignableLicenses(): void {
        let sortKey: string;
        let sortType: SortType = SortType.none;
        //sort
        for (const header_info of this._assignableTableHeaderHandler.list) {
            if (header_info.sorter && header_info.sorter.currentSortType !== SortType.none) {
                sortKey = header_info.dataKey;
                sortType = header_info.sorter.currentSortType;
                break;
            }
        }

        if (sortKey && sortType !== SortType.none) {
            this._devLicenseRecord[this._currentAssignableLicenseOwner.deviceID].licenseList = this._devLicenseRecord[this._currentAssignableLicenseOwner.deviceID].licenseList.sort((a: ILicenseRelocateInfo, b: ILicenseRelocateInfo) => {
                if (a[sortKey] === undefined) {
                    return -1;
                }
                if (b[sortKey] === undefined) {
                    return -1;
                }

                if (a[sortKey] === b[sortKey]) {
                    return 0;
                }
                else if (a[sortKey] > b[sortKey]) {
                    return sortType === SortType.ascend ? -1 : 1;
                }
                else {
                    return sortType === SortType.ascend ? 1 : -1;
                }
            });
        }
    }

    private isLicenseChanged(): boolean {
        if (!this._selectDevice || !this._selectDevice.virtualId || !this._devLicenseRecord[this._selectDevice.virtualId]) {
            return false;
        }

        for (let l of this._devLicenseRecord[this._selectDevice.virtualId].licenseList) {
            if (l.from !== l.to) {
                return true;
            }
        }

        return false;
    }

    selectLicense(l: ILicenseRelocateInfo): void {
        if (l.isTransferable && !l.isExpired) {
            l.selected = !l.selected;
        }
    }

    allowRelease(): boolean {
        if (!this._selectDevice || !this._selectDevice.virtualId) {
            return false;
        }

        if (this._selectDevice.virtualId === this._currentAssignableLicenseOwner.deviceID) {
            return false;
        }

        for (let l of this._devLicenseRecord[this._selectDevice.virtualId].licenseList) {
            if (l.selected) {
                return true;
            }
        }

        return false;
    }

    allowAssign(): boolean {
        if (!this._selectDevice || !this._selectDevice.virtualId) {
            return false;
        }

        if (this._selectDevice.virtualId === this._currentAssignableLicenseOwner.deviceID) {
            return false;
        }

        for (let l of this._devLicenseRecord[this._currentAssignableLicenseOwner.deviceID].licenseList) {
            if (l.selected) {
                return true;
            }
        }

        return false;
    }

    private releaseLicenses(): void {
        if (!this._selectDevice || !this._selectDevice.virtualId) {
            return;
        }

        const from: string = this._selectDevice.virtualId;
        const to: string = this._currentAssignableLicenseOwner.deviceID;
        if (this._devLicenseRecord[from] && this._devLicenseRecord[to]) {
            this._devLicenseRecord[from].licenseList.filter(l => l.selected).forEach(l => {
                this._devLicenseRecord[from].licenseList.splice(this._devLicenseRecord[from].licenseList.indexOf(l), 1);
                this._devLicenseRecord[to].licenseList.push(l);

                l.to = to;
                l.selected = false;
            });
        }

        this.filterAssignableLicenses();
        this.filterAssignedLicenses();

    }

    private assignLicenses(): void {
        const from: string = this._currentAssignableLicenseOwner.deviceID;
        const to: string = this._selectDevice.virtualId;
        if (this._devLicenseRecord[from] && this._devLicenseRecord[to]) {
            this._devLicenseRecord[from].licenseList.filter(l => l.selected).forEach(l => {
                this._devLicenseRecord[from].licenseList.splice(this._devLicenseRecord[from].licenseList.indexOf(l), 1);
                this._devLicenseRecord[to].licenseList.push(l);

                l.to = to;
                l.selected = false;
            });
        }

        this.filterAssignableLicenses();
        this.filterAssignedLicenses();
    }

    private recover(): void {
        Object.keys(this._devLicenseRecord).forEach(vID => {
            const removeList: ILicenseRelocateInfo[] = [];
            this._devLicenseRecord[vID].licenseList.filter(l => l.from !== l.to).forEach(l => {
                if (l.from !== vID) {
                    removeList.push(l);
                    this._devLicenseRecord[l.from].licenseList.push(l);
                    l.to = l.from;
                }
            });

            removeList.forEach(l => {
                this._devLicenseRecord[vID].licenseList.splice(this._devLicenseRecord[vID].licenseList.indexOf(l), 1);
            });
        });
    }

    allowSubmit(): boolean {
        if (this._page === DialogPage.confirm) {
            return this._confirmDevLicenseList.length === 0 ? false : true;
        }

        return super.allowSubmit();
    }

    goNext(): void {
        switch (this._page) {
            case DialogPage.prepare:
                {
                    super.goNext();
                    this.init();
                }
                break;
            case DialogPage.submit:
                {
                    super.goNext();
                }
                break;
            case DialogPage.action:
                {
                    //reset
                    this._confirmDevLicenseList = [];

                    const confirmData: {
                        [vID: string]: {
                            changed: boolean,
                            origin: { [type: string]: { changeType: string, licenseInfo: ILicenseRelocateInfo }[] },
                            final: { [type: string]: { changeType: string, licenseInfo: ILicenseRelocateInfo }[] }
                        }
                    } = {};

                    Object.keys(this._devLicenseRecord).forEach((vID: string) => {
                        confirmData[vID] = { changed: false, origin: {}, final: {} };
                    });

                    Object.keys(this._devLicenseRecord).forEach((vID: string) => {
                        this._devLicenseRecord[vID].licenseList.forEach(l => {
                            if (l.from === l.to) {
                                const info = { changeType: '~', licenseInfo: l };
                                confirmData[vID].origin[l.type] = confirmData[vID].origin[l.type] || [];
                                confirmData[vID].final[l.type] = confirmData[vID].final[l.type] || [];
                                confirmData[vID].origin[l.type].push(info);
                                confirmData[vID].final[l.type].push(info);

                                return;
                            }

                            if (l.from === vID) {
                                confirmData[vID].changed = true;
                                confirmData[l.to].changed = true;
                                confirmData[vID].origin[l.type] = confirmData[vID].origin[l.type] || [];
                                confirmData[l.to].final[l.type] = confirmData[l.to].final[l.type] || [];
                                confirmData[vID].origin[l.type].push({ changeType: '-', licenseInfo: l });
                                confirmData[l.to].final[l.type].push({ changeType: '+', licenseInfo: l });
                            }
                            else if (l.to === vID) {
                                confirmData[l.from].changed = true;
                                confirmData[vID].changed = true;
                                confirmData[l.from].origin[l.type] = confirmData[l.from].origin[l.type] || [];
                                confirmData[vID].final[l.type] = confirmData[vID].final[l.type] || [];
                                confirmData[l.from].origin[l.type].push({ changeType: '-', licenseInfo: l });
                                confirmData[vID].final[l.type].push({ changeType: '+', licenseInfo: l });
                            }
                        });
                    });

                    this._confirmDevLicenseList = Object.keys(confirmData).filter(vID => confirmData[vID].changed && vID !== this.ACCOUNT_ALIAS).map(vID => {
                        return {
                            device: this._devLicenseRecord[vID].device,
                            originalAllocation: confirmData[vID].origin,
                            newAllocation: confirmData[vID].final,
                            originalLicenseTypeList: Object.keys(confirmData[vID].origin).filter(type => confirmData[vID].origin[type].length > 0).map(type => ({ type: type, showName: confirmData[vID].origin[type][0].licenseInfo.showName })),
                            newLicenseTypeList: Object.keys(confirmData[vID].final).filter(type => confirmData[vID].final[type].length > 0).map(type => ({ type: type, showName: confirmData[vID].final[type][0].licenseInfo.showName }))
                        }
                    });

                    super.goNext();
                }
                break;
            case DialogPage.confirm:
                {
                    super.goNext();

                    const reqMap: {
                        [vID: string]: {
                            licenseMap: { [token: string]: string },
                            targetDevice: DeviceInfo
                        }
                    } = {};

                    const needUpdateDeviceMap: { [vID: string]: boolean } = {};
                    this._confirmDevLicenseList.forEach(c => {
                        let bChange: boolean = false;
                        Object.keys(c.originalAllocation).forEach((licenseType: string) => {
                            c.originalAllocation[licenseType].filter(i => i.changeType === '-').forEach(i => {
                                if (i.licenseInfo.to) {
                                    reqMap[i.licenseInfo.to] = reqMap[i.licenseInfo.to] || { licenseMap: {}, targetDevice: null };
                                    reqMap[i.licenseInfo.to].licenseMap[i.licenseInfo.token] = i.licenseInfo.code;
                                    reqMap[i.licenseInfo.to].targetDevice = this._devLicenseRecord[i.licenseInfo.to].device;
                                    if (i.licenseInfo.to !== this.ACCOUNT_ALIAS) {
                                        needUpdateDeviceMap[i.licenseInfo.to] = true;
                                    }
                                }
                                else {
                                    reqMap[this.ACCOUNT_ALIAS] = reqMap[this.ACCOUNT_ALIAS] || { licenseMap: {}, targetDevice: null };
                                    reqMap[this.ACCOUNT_ALIAS].licenseMap[i.licenseInfo.token] = i.licenseInfo.code;
                                }

                                bChange = true;
                            });
                        });

                        if (bChange) {
                            needUpdateDeviceMap[c.device.virtualId] = true;
                        }

                        Object.keys(c.newAllocation).forEach((licenseType: string) => {
                            c.newAllocation[licenseType].filter(i => i.changeType === '+').forEach(i => {
                                reqMap[c.device.virtualId] = reqMap[c.device.virtualId] || { licenseMap: {}, targetDevice: null };
                                reqMap[c.device.virtualId].licenseMap[i.licenseInfo.token] = i.licenseInfo.code;
                                reqMap[c.device.virtualId].targetDevice = c.device;

                                needUpdateDeviceMap[i.licenseInfo.to] = true;
                                if (i.licenseInfo.from !== this.ACCOUNT_ALIAS) {
                                    needUpdateDeviceMap[i.licenseInfo.from] = true;
                                }
                            });
                        });
                    });

                    const reqs: {
                        licenseDataList: { licenseToken: string, licenseCode: string }[],
                        targetDevice: DeviceInfo,
                    }[] = Object.keys(reqMap).map(vID => {
                        return {
                            targetDevice: reqMap[vID].targetDevice,
                            licenseDataList: Object.keys(reqMap[vID].licenseMap).map(token => {
                                return {
                                    licenseToken: token,
                                    licenseCode: reqMap[vID].licenseMap[token]
                                }
                            })
                        }
                    });

                    this.licenseSvc.assignLicenseToMultipleDevice(reqs, needUpdateDeviceMap).subscribe((res: { hasNext: boolean, result?: { targetDevice: DeviceInfo, licenseCodeList: string[], isFault: boolean, errorMessage?: string }[] }) => {
                        if (res && !res.hasNext) {
                            this._licenseAssignResultList = res.result;
                            super.goNext();
                        }
                    });
                }
                break;
        }
    }

    private init(force: boolean = false): void {
        this._loading = true;

        const source$ = this.licenseSvc.getLicenseByDeviceList(this._legalDevices.map(d => d.virtualId), force).pipe(
            map(ret => {
                if (ret.isFault || !ret.licenseData) {
                    return;
                }

                Object.keys(ret.licenseData).forEach(vID => {
                    if (ret.licenseData[vID]) {
                        for (const licenseCategory of Object.keys(ret.licenseData[vID].licenses)) {
                            for (const licenseType of Object.keys(ret.licenseData[vID].licenses[licenseCategory].detail)) {
                                for (const l of ret.licenseData[vID].licenses[licenseCategory].detail[licenseType].licenseKeyList) {
                                    if (this._devLicenseRecord[vID]) {
                                        this._devLicenseRecord[vID].licenseList = this._devLicenseRecord[vID].licenseList || [];
                                        this._devLicenseRecord[vID].licenseList.push({
                                            token: l.licenseKeyToken,
                                            category: licenseCategory,
                                            type: licenseType,
                                            code: '*-' + l.licenseKeyPartialCode,
                                            expireDate: l.licenseKeyExpiryDate ? l.licenseKeyExpiryDate.substring(0, 10) + 'Z' : this.ACCOUNT_ALIAS,
                                            isActivated: l.isLicenseKeyActivated,
                                            isExpired: l.isLicenseKeyExpired,
                                            isTransferable: l.license.isLicenseTransferable,
                                            selected: false,
                                            from: vID,
                                            to: vID,
                                            showName: l.license.licenseShowName || l.license.licenseName
                                        });

                                        this._allLicenseMap[l.licenseKeyToken] = true;
                                    }
                                }
                            }
                        }
                    }
                });

                return ret.hasNext;
            }),
            share()
        );

        source$.pipe(
            buffer(source$.pipe(filter((hasNext: boolean) => !hasNext))),
            concatMap((results: boolean[]) => this.licenseSvc.getLicenseByAccount(force)),
            map((ownerLicenseMap: { [licenseCategory: string]: { detail: { [licenseType: string]: { licenseKeyList: ILicenseInfo[]; } } } }) => {
                if (ownerLicenseMap) {
                    for (const licenseCategory of Object.keys(ownerLicenseMap)) {
                        for (const licenseType of Object.keys(ownerLicenseMap[licenseCategory].detail)) {
                            for (const l of ownerLicenseMap[licenseCategory].detail[licenseType].licenseKeyList) {
                                if (l.isLicenseKeyExpired) {
                                    continue;
                                }

                                if (!l.license.isLicenseTransferable) {
                                    Logger.logInfo('LicenseReallocate', 'init', 'license data missing = ' + l.licenseKeyToken);
                                    continue;
                                }

                                const assigneeID: string = l.licenseKeyAssignee && l.licenseKeyAssignee.virtualDeviceID ? l.licenseKeyAssignee.virtualDeviceID : this.ACCOUNT_ALIAS;
                                if (!this._allLicenseMap[l.licenseKeyToken] && this._devLicenseRecord[assigneeID]) {
                                    this._devLicenseRecord[assigneeID].licenseList = this._devLicenseRecord[assigneeID].licenseList || [];
                                    this._devLicenseRecord[assigneeID].licenseList.push({
                                        token: l.licenseKeyToken,
                                        category: licenseCategory,
                                        type: licenseType,
                                        code: '*-' + l.licenseKeyPartialCode,
                                        expireDate: l.licenseKeyExpiryDate ? l.licenseKeyExpiryDate.substring(0, 10) + 'Z' : this.ACCOUNT_ALIAS,
                                        isActivated: l.isLicenseKeyActivated,
                                        isExpired: l.isLicenseKeyExpired,
                                        isTransferable: l.license.isLicenseTransferable,
                                        selected: false,
                                        from: assigneeID,
                                        to: assigneeID,
                                        showName: l.license.licenseShowName || l.license.licenseName
                                    });

                                    this._allLicenseMap[l.licenseKeyToken] = true;
                                }
                            }
                        }
                    }
                }
            })
        ).subscribe(() => {
            //init header
            this._playerTableHeaderHandler.init([
                {
                    displayName: 'License',
                    dataKey: 'code', //'licenseKeyPartialCode'
                },
                {
                    displayName: 'Type',
                    dataKey: 'showName', //'licenseType',
                    sorter: {
                        currentSortType: SortType.none
                    }
                },
                {
                    displayName: 'Expiration',
                    dataKey: 'expireDate', //'licenseExpireDate',
                    sorter: {
                        currentSortType: SortType.none
                    }
                }
            ]);

            const assignableAssigneeFilterList: { displayName: string, key: string }[] =
                this._legalDevices.map(d => {
                    return {
                        displayName: d.virtualName,
                        key: d.virtualId
                    }
                });

            assignableAssigneeFilterList.push({
                displayName: '< Nobody >',
                key: this.ACCOUNT_ALIAS
            });

            this._assignableLicenseOwnerList = this._legalDevices.map(d => ({ displayName: d.virtualName, deviceID: d.virtualId }));
            this._currentAssignableLicenseOwner = { displayName: '< Nobody >', deviceID: this.ACCOUNT_ALIAS };
            this._assignableLicenseOwnerList.push(this._currentAssignableLicenseOwner);

            this._assignableTableHeaderHandler.init([
                {
                    displayName: 'License',
                    dataKey: 'code', //'licenseKeyPartialCode'
                },
                {
                    displayName: 'type',
                    dataKey: 'showName', //'licenseType',
                    sorter: {
                        currentSortType: SortType.none
                    }
                },
                {
                    displayName: 'Expiration',
                    dataKey: 'expireDate', //'licenseExpireDate',
                    sorter: {
                        currentSortType: SortType.none
                    }
                }
            ]);

            this.filterAssignableLicenses();
            this.refactorLicenseRequestorDevices();
            this.refactorLicenseOwnerDevices();

            this._loading = false;
        });
    }

    changeAssignableLicenseOwner(licenseOwner: { displayName: string, deviceID: string }): void {
        this._currentAssignableLicenseOwner = licenseOwner;
    }

    protected isPageValid(page: DialogPage): boolean {
        if (page === DialogPage.prepare) {
            //check if devices length > 0
            return this._legalDevices.length > 0 ? true : false;
        }
        else if (this._page === DialogPage.action) {
            return this._selectDevice ? true : false;
        }

        return super.isPageValid(page);
    }

    switchToAnotherPlayer(allowSwitch: boolean): void {
        if (allowSwitch && this._playerToSwitch) {
            if (this._selectDevice !== this._playerToSwitch) {
                this.recover();

                this._selectDevice = this._playerToSwitch;
                this.filterAssignableLicenses();
            }
        }

        this._showSwitchPlayerWarning = false;
        this._playerToSwitch = null;
    }

    private refactorLicenseRequestorDevices(filterName?: string): void {
        this._displayLicenseRequestorDevices = filterName ? this._legalDevices.filter(d => d.virtualName.toLocaleLowerCase().indexOf(filterName) >= 0) : this._legalDevices.map(d => d);
    }

    private refactorLicenseOwnerDevices(filterName?: string): void {
        this._displayLicenseOwnerDevices = filterName ? this._assignableLicenseOwnerList.filter(d => d.displayName.toLocaleLowerCase().indexOf(filterName) >= 0) : this._assignableLicenseOwnerList.map(d => d);
    }
}